import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import  from "@material-ui/icons/";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import Muted from "components/Typography/Muted.js";

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";

import bg7 from "assets/img/bg7.jpg";
import city from "assets/img/examples/city.jpg";
import marc from "assets/img/faces/marc.jpg";
import christian from "assets/img/faces/christian.jpg";
import kendall from "assets/img/faces/kendall.jpg";
import avatar from "assets/img/faces/avatar.jpg";
import cardProfile1 from "assets/img/examples/card-profile1.jpg";
import cardProfile2 from "assets/img/examples/card-profile2.jpg";
import cardProfile4 from "assets/img/examples/card-profile4.jpg";
import cardProfile1Square from "assets/img/faces/card-profile1-square.jpg";
import cardProfile2Square from "assets/img/faces/card-profile2-square.jpg";
import cardProfile4Square from "assets/img/faces/card-profile4-square.jpg";
import cardProfile6Square from "assets/img/faces/card-profile6-square.jpg";
import ergunBatuhanOktem from "assets/img/faces/ergunBatuhanOktem.jpg";

const useStyles = makeStyles(teamsStyle);

export default function SectionTeams({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      {/* Team 4 START */}
      <div
        className={classes.team + " " + classes.section}
        style={{ backgroundImage: `url(${bg7})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>Our Team</h2>
              <h5 className={classes.descriptionWhite}>
                We work generally with developers remotely according to our
                needs.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card profile>
                <CardAvatar profile>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <img src={ergunBatuhanOktem} alt="..." />
                  </a>
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>Ergun Batuhan Oktem</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>FOUNDER</h6>
                  </Muted>
                  <p className={classes.description}>
                    He started computer programming when he was 6 years old with
                    Commodore 64 in Commodore Basic. He has been following
                    computer journals monthly since my childhood. He has been
                    developing mobile applications in iOS, Android, Windows
                    Phone and Symbian by using C#, Swift, Objective C, Java and
                    Qt. He has been using .NET Framework in most of my projects.
                    He has been developing Windows applications by using Windows
                    Forms and WPF. He has been developing web applications by
                    using ASP.NET Web Forms and MVC. He has been developing
                    games by using Unity, Cocos2d-x. He has been developing
                    cross platform mobile applications by using Xamarin. He has
                    been developing REST API by using ASP.NET Core. He has been
                    using React, JQuery, AngularJS, Vue.js for frontend
                    development. He has been using Node.js in some of my
                    projects. He has used Tesseract for captcha recognition from
                    images. He has been developing AR, VR, MR applications by
                    using ARKit, ARCore, Vuforia. He has experience in SQL,
                    NoSQL databases such as MSSQL, MySQL, Oracle, PostgreSQL,
                    SQLite, MongoDB, Cassandra, Redis. He has been using Linux
                    VPS and dedicated servers from AWS, DigitalOcean and KimSufi
                    (Generally CentOS and Ubuntu based systems). He has been
                    using Docker for containerization. As a result, he is
                    improving myself in technology areas especially in computer
                    technology. He can learn new technologies easily.
                  </p>
                </CardBody>
                <CardFooter profile className={classes.justifyContent}>
                  <Button
                    href="https://www.linkedin.com/in/batuhanoktem"
                    justIcon
                    round
                    color="linkedin"
                  >
                    <i className="fab fa-linkedin" />
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Team 4 END */}
    </div>
  );
}
