import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import Share from "@material-ui/icons/Share";
import ChatBubble from "@material-ui/icons/ChatBubble";
import Schedule from "@material-ui/icons/Schedule";
import TrendingUp from "@material-ui/icons/TrendingUp";
import Subject from "@material-ui/icons/Subject";
import WatchLater from "@material-ui/icons/WatchLater";
import People from "@material-ui/icons/People";
import Business from "@material-ui/icons/Business";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Delete from "@material-ui/icons/Delete";
import Bookmark from "@material-ui/icons/Bookmark";
import Refresh from "@material-ui/icons/Refresh";
import Receipt from "@material-ui/icons/Receipt";
import PhoneIphone from "@material-ui/icons/PhoneIphone";
import Web from "@material-ui/icons/Web";
import DesktopWindows from "@material-ui/icons/DesktopWindows";
import SportsEsports from "@material-ui/icons/SportsEsports";
import Search from "@material-ui/icons/Search";
import GroupWork from "@material-ui/icons/GroupWork";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardAvatar from "components/Card/CardAvatar.js";
import Info from "components/Typography/Info.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";
import Rose from "components/Typography/Rose.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";
import descriptionStyle from "assets/jss/material-kit-pro-react/views/presentationSections/descriptionStyle.js";

import cardBlog1 from "assets/img/examples/card-blog1.jpg";
import cardBlog2 from "assets/img/examples/card-blog2.jpg";
import cardBlog3 from "assets/img/examples/card-blog3.jpg";
import cardBlog5 from "assets/img/examples/card-blog5.jpg";
import cardBlog6 from "assets/img/examples/card-blog6.jpg";
import cardProfile1 from "assets/img/examples/card-profile1.jpg";
import cardProfile4 from "assets/img/examples/card-profile4.jpg";
import blog1 from "assets/img/examples/blog1.jpg";
import blog5 from "assets/img/examples/blog5.jpg";
import blog6 from "assets/img/examples/blog6.jpg";
import blog8 from "assets/img/examples/blog8.jpg";
import avatar from "assets/img/faces/avatar.jpg";
import christian from "assets/img/faces/christian.jpg";
import marc from "assets/img/faces/marc.jpg";
import office1 from "assets/img/examples/office1.jpg";
import color1 from "assets/img/examples/color1.jpg";
import color2 from "assets/img/examples/color2.jpg";
import color3 from "assets/img/examples/color3.jpg";

const useStyles = makeStyles(styles);
const useDescriptionStyles = makeStyles(descriptionStyle);

export default function SectionCards() {
  const [activeRotate1, setActiveRotate1] = React.useState("");
  const [activeRotate2, setActiveRotate2] = React.useState("");
  const [activeRotate3, setActiveRotate3] = React.useState("");
  const classes = useStyles();
  const classesDescription = useDescriptionStyles();
  React.useEffect(() => {
    if (window) {
      window.addEventListener("resize", addStylesForRotatingCards);
    }
    addStylesForRotatingCards();
    return function cleanup() {
      if (window) {
        window.removeEventListener("resize", addStylesForRotatingCards);
      }
    };
  });
  const addStylesForRotatingCards = () => {
    var rotatingCards = document.getElementsByClassName(classes.cardRotate);
    for (let i = 0; i < rotatingCards.length; i++) {
      var rotatingCard = rotatingCards[i];
      var cardFront = rotatingCard.getElementsByClassName(classes.front)[0];
      var cardBack = rotatingCard.getElementsByClassName(classes.back)[0];
      cardFront.style.height = "unset";
      cardFront.style.width = "unset";
      cardBack.style.height = "unset";
      cardBack.style.width = "unset";
      var rotatingWrapper = rotatingCard.parentElement;
      var cardWidth = rotatingCard.parentElement.offsetWidth;
      var cardHeight = rotatingCard.children[0].children[0].offsetHeight;
      rotatingWrapper.style.height = cardHeight + "px";
      rotatingWrapper.style["margin-bottom"] = 30 + "px";
      cardFront.style.height = "unset";
      cardFront.style.width = "unset";
      cardBack.style.height = "unset";
      cardBack.style.width = "unset";
      cardFront.style.height = cardHeight + 35 + "px";
      cardFront.style.width = cardWidth + "px";
      cardBack.style.height = cardHeight + 35 + "px";
      cardBack.style.width = cardWidth + "px";
    }
  };
  return (
    <div className={classesDescription.section} id="cards">
      <div>
        {/* MORPHING CARDS START */}
        <div id="morphingCards" className="cd-section">
          <div className={classes.container}>
            <div className={classes.title}>
              <h3 className={classesDescription.description}>Services</h3>
            </div>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6} lg={4}>
                <div className={classes.rotatingCardContainer}>
                  <Card className={classes.cardRotate}>
                    <div className={classes.front}>
                      <CardBody className={classes.cardBodyRotate}>
                        <Success>
                          <h5 className={classes.cardCategorySocial}>
                            <Web />
                            Web Development
                          </h5>
                        </Success>
                        <h4 className={classes.cardTitle}>
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            We make static and dynamic web applications.
                          </a>
                        </h4>
                        <p className={classes.cardDescription}>
                          We have web designs for many industries. Also we
                          prepare custom designs according to your ideas and
                          make custom dashboard designs for you.
                        </p>
                      </CardBody>
                    </div>
                    <div className={classes.back}>
                      <CardBody className={classes.cardBodyRotate}>
                        <h5 className={classes.cardTitle}>Technologies</h5>
                        <p className={classes.cardDescription}>
                          ASP.NET Core, Node.js, Vue, React, Angular, HTML, CSS,
                          Wordpress
                        </p>
                        <div className={classes.textCenter}>
                          {/*
                          <Button round color="rose">
                            <Subject /> Read
                          </Button>
                          */}
                        </div>
                      </CardBody>
                    </div>
                  </Card>
                </div>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={4}>
                <div className={classes.rotatingCardContainer}>
                  <Card className={classes.cardRotate}>
                    <div className={classes.front}>
                      <CardBody className={classes.cardBodyRotate}>
                        <Success>
                          <h5 className={classes.cardCategorySocial}>
                            <PhoneIphone />
                            Mobile Development
                          </h5>
                        </Success>
                        <h4 className={classes.cardTitle}>
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            We make static and dynamic mobile applications.
                          </a>
                        </h4>
                        <p className={classes.cardDescription}>
                          Our mobile applications work in many platforms like
                          iOS, Android, UWP. We make offline and online
                          applications with admin dashboard can be managed by
                          web.
                        </p>
                      </CardBody>
                    </div>
                    <div className={classes.back}>
                      <CardBody className={classes.cardBodyRotate}>
                        <h5 className={classes.cardTitle}>Technologies</h5>
                        <p className={classes.cardDescription}>
                          Swift, Objective C, Java, Xamarin, React Native, Vue
                          Native, Qt
                        </p>
                        <div className={classes.textCenter}>
                          {/*
                          <Button round color="rose">
                            <Subject /> Read
                          </Button>
                          */}
                        </div>
                      </CardBody>
                    </div>
                  </Card>
                </div>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={4}>
                <div className={classes.rotatingCardContainer}>
                  <Card className={classes.cardRotate}>
                    <div className={classes.front}>
                      <CardBody className={classes.cardBodyRotate}>
                        <Success>
                          <h5 className={classes.cardCategorySocial}>
                            <DesktopWindows />
                            Desktop Development
                          </h5>
                        </Success>
                        <h4 className={classes.cardTitle}>
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            We make static and dynamic desktop applications.
                          </a>
                        </h4>
                        <p className={classes.cardDescription}>
                          We make desktop applications for Windows, MacOS and
                          Linux platforms.
                        </p>
                      </CardBody>
                    </div>
                    <div className={classes.back}>
                      <CardBody className={classes.cardBodyRotate}>
                        <h5 className={classes.cardTitle}>Technologies</h5>
                        <p className={classes.cardDescription}>
                          Windows Forms, WPF, Qt, Gtk#
                        </p>
                        <div className={classes.textCenter}>
                          {/*
                          <Button round color="rose">
                            <Subject /> Read
                          </Button>
                          */}
                        </div>
                      </CardBody>
                    </div>
                  </Card>
                </div>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={4}>
                <div className={classes.rotatingCardContainer}>
                  <Card className={classes.cardRotate}>
                    <div className={classes.front}>
                      <CardBody className={classes.cardBodyRotate}>
                        <Success>
                          <h5 className={classes.cardCategorySocial}>
                            <SportsEsports />
                            Game Development
                          </h5>
                        </Success>
                        <h4 className={classes.cardTitle}>
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            We make 2D and 3D games.
                          </a>
                        </h4>
                        <p className={classes.cardDescription}>
                          We make games in many categories like action, puzzle,
                          platform, sports etc.
                        </p>
                      </CardBody>
                    </div>
                    <div className={classes.back}>
                      <CardBody className={classes.cardBodyRotate}>
                        <h5 className={classes.cardTitle}>Technologies</h5>
                        <p className={classes.cardDescription}>
                          Unity, Cocos2d-x, Unreal Engine
                        </p>
                        <div className={classes.textCenter}>
                          {/*
                          <Button round color="rose">
                            <Subject /> Read
                          </Button>
                          */}
                        </div>
                      </CardBody>
                    </div>
                  </Card>
                </div>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={4}>
                <div className={classes.rotatingCardContainer}>
                  <Card className={classes.cardRotate}>
                    <div className={classes.front}>
                      <CardBody className={classes.cardBodyRotate}>
                        <Success>
                          <h5 className={classes.cardCategorySocial}>
                            <Search />
                            SEO & ASO
                          </h5>
                        </Success>
                        <h4 className={classes.cardTitle}>
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            We make search engine and app store optimization.
                          </a>
                        </h4>
                        <p className={classes.cardDescription}>
                          We optimize your website to be find easily on search
                          engines like Google, Yandex. We optimize your
                          application to be find on app stores like Apple App
                          Store and Google Play Store.
                        </p>
                      </CardBody>
                    </div>
                    <div className={classes.back}>
                      <CardBody className={classes.cardBodyRotate}>
                        <h5 className={classes.cardTitle}>Services</h5>
                        <p className={classes.cardDescription}>
                          Consultancy, Finding Keywords, Market Research,
                          Content Writing
                        </p>
                        <div className={classes.textCenter}>
                          {/*
                          <Button round color="rose">
                            <Subject /> Read
                          </Button>
                          */}
                        </div>
                      </CardBody>
                    </div>
                  </Card>
                </div>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={4}>
                <div className={classes.rotatingCardContainer}>
                  <Card className={classes.cardRotate}>
                    <div className={classes.front}>
                      <CardBody className={classes.cardBodyRotate}>
                        <Success>
                          <h5 className={classes.cardCategorySocial}>
                            <GroupWork />
                            Hire Us
                          </h5>
                        </Success>
                        <h4 className={classes.cardTitle}>
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            If you have a project and you need a team member,
                            you can hire us.
                          </a>
                        </h4>
                        <p className={classes.cardDescription}>
                          We provide expert developers, mentors for your
                          projects. According to your preferred timezone, we
                          make plan for meetings, development etc.
                        </p>
                      </CardBody>
                    </div>
                    <div className={classes.back}>
                      <CardBody className={classes.cardBodyRotate}>
                        <h5 className={classes.cardTitle}>Positions</h5>
                        <p className={classes.cardDescription}>
                          Unity Developer, C# Developer, C++ Developer,
                          Objective C Developer, Swift Developer, Xamarin
                          Developer, Java Developer etc.
                        </p>
                        <div className={classes.textCenter}>
                          {/*
                          <Button round color="rose">
                            <Subject /> Read
                          </Button>
                          */}
                        </div>
                      </CardBody>
                    </div>
                  </Card>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        {/* MORPHING CARDS END */}
      </div>
    </div>
  );
}
